export class StringUtils {
  static removeSpecialCharacters(input: string, localization?: string): string {
    input = input.toLowerCase().replace(/ /g, '-');

    input = input
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/ü/g, 'ue')
      .replace(/ß/g, 'ss');

    if (localization) {
      if (localization === 'en') {
        input = input.replace(/&/g, 'and');
      } else if (localization === 'de') {
        input = input.replace(/&/g, 'und');
      }
    }

    input = input.replace(/[^a-zA-Z0-9-]+/g, '');

    return input;
  }

  static areStringArraysEqual(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) return false;

    const arr2Copy = [...arr2];

    for (const str of arr1) {
      const index = arr2Copy.indexOf(str);
      if (index === -1) return false;
      arr2Copy.splice(index, 1);
    }

    return arr2Copy.length === 0;
  }
}

export enum RSVPOptionType {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
  ALL = 'ALL',

  // used for filter
  WAITLIST = 'WAITLIST',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  INVITED = 'INVITED',
  CHECKED = 'CHECKED',
}

export namespace RSVPOptionType {
  export function getAllTypes(): RSVPOptionType[] {
    return [RSVPOptionType.YES, RSVPOptionType.NO, RSVPOptionType.MAYBE];
  }

  export function getDisplayName(relevance?: RSVPOptionType): string {
    switch (relevance) {
      case RSVPOptionType.YES:
        return 'APP.RSVP_OPTION.YES';
      case RSVPOptionType.NO:
        return 'APP.RSVP_OPTION.NO';
      case RSVPOptionType.MAYBE:
        return 'APP.RSVP_OPTION.MAYBE';
      case RSVPOptionType.WAITLIST:
        return 'APP.RSVP_OPTION.WAITLIST';
      case RSVPOptionType.PENDING_APPROVAL:
        return 'APP.RSVP_OPTION.PENDING_APPROVAL';
      case RSVPOptionType.INVITED:
        return 'APP.RSVP_OPTION.INVITED';
      case RSVPOptionType.CHECKED:
        return 'APP.RSVP_OPTION.CHECKED';
      default:
        return '';
    }
  }

  export function getRSVPDisplayName(relevance?: RSVPOptionType): string {
    switch (relevance) {
      case RSVPOptionType.YES:
        return 'APP.RSVP_OPTION.ALL_GOING';
      case RSVPOptionType.NO:
        return 'APP.RSVP_OPTION.NO';
      case RSVPOptionType.MAYBE:
        return 'APP.RSVP_OPTION.MAYBE';
      case RSVPOptionType.PENDING_APPROVAL:
        return 'APP.RSVP_OPTION.PENDING';
      case RSVPOptionType.INVITED:
        return 'APP.RSVP_OPTION.INVITED';
      default:
        return '';
    }
  }
}
